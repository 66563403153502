void (async () => {
  // Need to run this before importing the app
  // to be able to resolve translations in static objects.
  const { initI18next } = await import('./js/area/1_startup/shared/i18n');
  void initI18next();

  // Async import to allow loading WASM
  await import('./js/area/1_startup/isolated/bootstrap_tab');
})();

export {};
